@import url("https://fonts.googleapis.com/css2?family=Gluten:wght@100..900&family=Grandstander:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&family=Commissioner:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// Reset css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Gluten", sans-serif;
  font-size: 16px;
  background: #000;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}

.modal {
  position: fixed;
  z-index: 9999999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000000bf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal.hidden {
  display: none;
}

.modal button {
  padding: 15px 30px;
  background-color: red;
  color: #fff;
  font-size: 30px;
  border-radius: 10px;
  font-family: "Gluten", sans-serif;
}

// App
.App {
  position: relative;
  .container {
    margin: 0 auto;
    overflow: hidden;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1080px;
    overflow: hidden;
    video {
      width: 100%;
      height: 1080px;
      object-fit: cover;
    }
  }
  .page-header {
    height: 1080px;
    .container {
      position: relative;
      height: calc(1080px - 96px);
      padding: 48px 24px;
      .nav-menu {
        padding: 0 32px;
        background: #000000;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 999;
        @media only screen and (max-width: 880px) {
          flex-direction: column;
          padding-top: 16px;
          padding-bottom: 16px;
        }
        .img-logo {
          flex-shrink: 0;
          width: 115px;
          // @media only screen and (max-width: 612px) {
          //   width: 100px;
          // }
          // @media only screen and (max-width: 480px) {
          //   width: 80px;
          // }
        }
        ul {
          display: flex;
          align-items: center;
          gap: 100px;
          @media only screen and (max-width: 1268px) {
            gap: 32px;
          }
          @media only screen and (max-width: 700px) {
            gap: 12px;
          }
          @media only screen and (max-width: 480px) {
            gap: 8px;
          }
          li {
            padding: 19px 0;
            @media only screen and (max-width: 880px) {
              padding: 12px 0;
            }
            color: #fff;
            &:hover {
              opacity: 0.6;
            }
            cursor: pointer;
            font-family: Grandstander;
            font-size: 22px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            @media only screen and (max-width: 700px) {
              font-size: 20px;
            }
            @media only screen and (max-width: 612px) {
              line-height: 18px;
              font-size: 18px;
            }
            @media only screen and (max-width: 480px) {
              line-height: 18px;
              font-size: 14px;
            }
          }
        }
        .actions {
          display: flex;
          align-items: center;
          gap: 24px;
          img {
            width: 30px;
          }
        }
      }
      .main-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        height: 100%;
        height: calc(100% - 96px);
        gap: 134px;
        @media only screen and (max-width: 1487px) {
          gap: 60px;
        }
        @media only screen and (max-width: 880px) {
          margin-top: -170px;
        }
        .top {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 29px;
          h3 {
            font-size: 48px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0.8px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            @media only screen and (max-width: 728px) {
              line-height: normal;
            }
            @media only screen and (max-width: 480px) {
              font-size: 36px;
            }
          }
          .value {
            padding: 16px;
            width: fit-content;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 16px;
            color: #fff;
            font-family: "Baloo 2";
            font-size: 28px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            @media only screen and (max-width: 612px) {
              font-size: 18px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 16px;
            }
          }
          .btn-groups {
            display: flex;
            align-items: center;
            img {
              width: 200px;
              @media only screen and (max-width: 480px) {
                width: 150px;
              }
            }
          }
          .list-tools {
            display: flex;
            align-items: center;
            gap: 50px;
            @media only screen and (max-width: 890px) {
              gap: 24px;
            }
            @media only screen and (max-width: 768px) {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  .page-main {
    .section-about {
      background-image: url(assets/images/img-bg-section-2.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .container {
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 160px 24px 110px;
        @media only screen and (max-width: 986px) {
          padding: 60px 24px;
        }
        .content {
          display: flex;
          align-items: center;
          gap: 64px;
          @media only screen and (max-width: 1024px) {
            gap: 24px;
          }
          @media only screen and (max-width: 986px) {
            flex-direction: column;
            justify-content: center;
            gap: 12px;
          }
          .left {
            width: 525px;
            @media only screen and (max-width: 986px) {
              width: 300px;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 986px) {
              align-items: center;
            }
            .img-title {
              width: 400px;
              @media only screen and (max-width: 986px) {
                width: 250px;
              }
              @media only screen and (max-width: 480px) {
                width: 200px;
              }
            }
            p {
              margin-top: 29px;
              font-size: 22px;
              font-weight: 400;
              line-height: 40px;
              text-align: left;
              max-width: 711px;
              &:last-child {
                margin-top: 21px;
              }
              @media only screen and (max-width: 1024px) {
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                margin-top: 12px;
                &:last-child {
                  margin-top: 12px;
                }
              }
              @media only screen and (max-width: 640px) {
                font-size: 16px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    .section-tokenomics {
      background-image: url(assets/images/img-bg-section-3.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .container {
        padding: 177px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 108px;
        @media only screen and (max-width: 948px) {
          padding: 100px 24px;
          gap: 60px;
        }
        @media only screen and (max-width: 768px) {
          padding: 60px 24px;
        }
        .img-title {
          width: 580px;
          @media only screen and (max-width: 640px) {
            width: auto;
          }
        }
        .list-item {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 69px;
          @media only screen and (max-width: 1180px) {
            gap: 32px;
          }
          @media only screen and (max-width: 1024px) {
            gap: 28px;
          }
          @media only screen and (max-width: 948px) {
            flex-direction: column;
          }
          li {
            position: relative;
            width: 326px;
            @media only screen and (max-width: 1080px) {
              width: 300px;
            }
            @media only screen and (max-width: 1024px) {
              width: 280px;
            }
            @media only screen and (max-width: 948px) {
              width: 326px;
            }
            @media only screen and (max-width: 480px) {
              width: 280px;
            }
            .content {
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
              width: 100%;
              .title {
                font-family: Grandstander;
                font-size: 30px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0.8px;
                text-align: center;
                text-shadow: 2px 2px 0 #fff, -2px 2px 0 #fff, -2px -2px 0 #fff,
                  2px -2px 0 #fff;
              }
              .value {
                font-family: Commissioner;
                font-size: 69px;
                font-weight: 700;
                line-height: 103.5px;
                text-align: center;
                text-shadow: 3px 3px 0 #fff, -3px 3px 0 #fff, -3px -3px 0 #fff,
                  3px -3px 0 #fff;
                @media only screen and (max-width: 480px) {
                  font-size: 48px;
                  line-height: 80px;
                }
              }
            }
          }
        }
      }
    }
    .section-roadmap {
      position: relative;
      .video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .container {
        height: 100%;
        padding: 140px 24px 80px;
        @media only screen and (max-width: 1120px) {
          padding: 80px 24px;
        }
        @media only screen and (max-width: 910px) {
          padding-top: 40px;
          padding-bottom: 40px;
        }
        display: flex;
        align-items: center;
        position: relative;
        flex-direction: column;
        gap: 116px;
        @media only screen and (max-width: 1120px) {
          gap: 40px;
        }
        @media only screen and (max-width: 910px) {
          gap: 24px;
        }
        .img-title {
          width: 512px;
          @media only screen and (max-width: 576px) {
            width: auto;
          }
        }
        .list-item {
          display: flex;
          align-items: center;
          gap: 20px;
          @media only screen and (max-width: 1120px) {
            flex-wrap: wrap;
            justify-content: center;
          }
          .item {
            border-radius: 25px;
            border: 2px solid rgba(255, 255, 255, 1);
            background: rgba(255, 255, 255, 0.3);
            padding: 45px 24px;
            min-width: 368px;
            @media only screen and (max-width: 1375px) {
              min-width: 320px;
            }
            @media only screen and (max-width: 1200px) {
              min-width: 300px;
              padding: 45px 16px;
            }
            @media only screen and (max-width: 1120px) {
              padding: 24px;
              min-width: 368px;
            }
            @media only screen and (max-width: 910px) {
              padding: 16px;
            }
            @media only screen and (max-width: 480px) {
              min-width: 260px;
            }
            P {
              font-family: Grandstander;
              text-shadow: 2px 2px 0 #fff, -2px 2px 0 #fff, -2px -2px 0 #fff,
                2px -2px 0 #fff;
              font-size: 30px;
              font-weight: 700;
              line-height: 32px;
              letter-spacing: 0.8px;
              text-align: center;
            }
            li {
              display: flex;
              gap: 12px;
              img {
                width: 30px;
              }
              padding-top: 19px;
              font-family: Grandstander;
              font-size: 25px;
              font-weight: 400;
              line-height: 36.4px;
              text-align: left;
              color: #fff;
              @media only screen and (max-width: 910px) {
                line-height: 28px;
              }
              @media only screen and (max-width: 480px) {
                padding-top: 8px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .section-how-to-buy {
      background-image: url(assets/images/img-bg-section-4.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 130px 24px 180px;
        @media only screen and (max-width: 968px) {
          padding: 60px 24px;
        }
        .img-title {
          width: 648px;
          @media only screen and (max-width: 724px) {
            width: auto;
          }
        }
        .list-step {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-top: 98px;
          @media only screen and (max-width: 968px) {
            margin-top: 36px;
          }
          li {
            border-radius: 17px;
            padding: 20px 38px;
            background: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            gap: 16px;
            max-width: calc(1300px - 76px);
            @media only screen and (max-width: 968px) {
              flex-direction: column;
              text-align: center;
            }
            @media only screen and (max-width: 480px) {
              padding: 20px;
            }
            .content-1 {
              flex-shrink: 0;
              width: 89px;
              font-family: Grandstander;
              font-size: 22px;
              font-weight: 700;
              line-height: 32px;
              text-align: left;
              color: #fff;
              @media only screen and (max-width: 968px) {
                text-align: center;
              }
            }
            .content-2 {
              flex-shrink: 0;
              width: 323px;
              color: #1dacf9;
              font-family: Grandstander;
              font-size: 30px;
              font-weight: 700;
              line-height: 32px;
              text-align: left;
              text-transform: uppercase;
              @media only screen and (max-width: 968px) {
                text-align: center;
              }
              @media only screen and (max-width: 480px) {
                font-size: 24px;
                width: auto;
              }
            }
            .content-3 {
              span {
                color: #ffe500;
              }
              max-width: 780px;
              font-family: Grandstander;
              font-size: 22px;
              font-weight: 400;
              line-height: 32px;
              text-align: left;
              color: #fff;
              text-transform: uppercase;
              @media only screen and (max-width: 968px) {
                text-align: center;
              }
              @media only screen and (max-width: 480px) {
                font-size: 18px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
    .section-community {
      background-image: url(assets/images/img-bg-section-5.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 24px;
        gap: 18px;
        .img-footer {
          width: 213px;
        }
        h3 {
          font-family: Grandstander;
          font-size: 60px;
          font-weight: 400;
          letter-spacing: 0.8px;
          text-align: center;
          color: #fff;
          span {
            color: #fbfe08;
          }
          @media only screen and (max-width: 480px) {
            font-size: 36px;
          }
        }
        p {
          color: #fff;
          font-size: 26px;
          font-weight: 400;
          line-height: 36.4px;
          text-align: center;
          max-width: 620px;
          @media only screen and (max-width: 480px) {
            font-size: 18px;
          }
        }
        .socials {
          display: flex;
          align-items: center;
          gap: 50px;
          @media only screen and (max-width: 480px) {
            gap: 25px;
          }
          img {
            width: 83px;
            @media only screen and (max-width: 480px) {
              width: 60px;
            }
          }
        }
      }
    }
  }
  .page-footer {
    .container {
      p {
        color: #fff;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        line-height: 24.5px;
        text-align: center;
        text-transform: uppercase;
        padding: 20px 0;
        span {
          color: #5d5fef;
        }
      }
    }
  }
}
